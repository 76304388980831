import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import SocialProfile from '../../../components/social-profile/social-profile';
import { IntroWrapper, IntroImage, IntroTitle, Desciption } from './style';
import {
  IoLogoFacebook
} from 'react-icons/io';

type IntroProps = {};

const SocialLinks = [
  {
    icon: <IoLogoFacebook />,
    url: 'https://www.facebook.com/TinyTogether/',
    tooltip: 'Facebook',
  },
  //{
  //  icon: <IoLogoInstagram />,
  //  url: '#',
  //  tooltip: 'Instagram',
  //},
  //{
  //   icon: <IoLogoTwitter />,
  //   url: 'https://twitter.com/TinyTogether',
  //   tooltip: 'Twitter',
  // },
  // {
  //   icon: <IoLogoLinkedin />,
  //   url: 'https://www.linkedin.com/company/redqinc/',
  //   tooltip: 'Linked In',
  // },
];

const Intro: React.FunctionComponent<IntroProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/author.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 210, maxHeight: 210, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `);

  const { author, about } = Data.site.siteMetadata;
  const AuthorImage = Data.avatar.childImageSharp.fluid;

  return (
    <IntroWrapper>
      <IntroImage>
        <Image fluid={AuthorImage} alt="author" />
      </IntroImage>
      <IntroTitle>
        Hey! We're <b>{author}</b>
      </IntroTitle>
      <Desciption>{about}</Desciption>
      <SocialProfile items={SocialLinks} />
    </IntroWrapper>
  );
};

export default Intro;
